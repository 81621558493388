// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 分页获取数据
export const getDeviceBuyList = (params) => {
    return postRequest('/busi/deviceBuy/getByPage', params)
}
// 添加
export const addDeviceBuy = (params) => {
    return postRequest('/busi/deviceBuy/save', params)
}
// 编辑
export const editDeviceBuy = (params) => {
    return postRequest('/busi/deviceBuy/insertOrUpdate', params)
}
// 删除
export const deleteDeviceBuy = (params) => {
    return postRequest('/busi/deviceBuy/delByIds', params)
}