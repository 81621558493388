<template>
  <div>
    <Card>
      <div slot="title">
        <div class="edit-head">
          <a @click="close" class="back-title">
            <Icon type="ios-arrow-back" />返回
          </a>
          <div v-if="addEditFlag=='1'" class="head-name">添加</div>
          <div v-else class="head-name">编辑</div>
          <span></span>
          <a @click="close" class="window-close">
            <Icon type="ios-close" size="31" class="ivu-icon-ios-close" />
          </a>
        </div>
      </div>
      <Form ref="form" :model="form" :label-width="100" :rules="formValidate" label-position="left" >          <FormItem label="手机号码" prop="telno"  >
            <Input v-model="form.telno" clearable style="width:570px"/>
          </FormItem>
          <FormItem label="发生日期" prop="fsrq"  >
            <Input v-model="form.fsrq" clearable style="width:570px"/>
          </FormItem>
          <FormItem label="交易单号" prop="tradeno"  >
            <Input v-model="form.tradeno" clearable style="width:570px"/>
          </FormItem>
          <FormItem label="IMEI码" prop="imei"  >
            <Input v-model="form.imei" clearable style="width:570px"/>
          </FormItem>
          <FormItem label="序列号" prop="ensn"  >
            <Input v-model="form.ensn" clearable style="width:570px"/>
          </FormItem>
          <FormItem label="IMEI码2" prop="imei2"  >
            <Input v-model="form.imei2" clearable style="width:570px"/>
          </FormItem>
          <FormItem label="序列号2" prop="ensn2"  >
            <Input v-model="form.ensn2" clearable style="width:570px"/>
          </FormItem>
          <FormItem label="购买类型" prop="buytype"  >
            <Input v-model="form.buytype" clearable style="width:570px"/>
          </FormItem>
          <Form-item class="br">
            <Button
              @click="handleSubmit"
              :loading="submitLoading"
              type="primary"
            >提交并保存</Button>
            <!-- <Button @click="handleReset">重置</Button> -->
            <Button type="dashed" @click="close">关闭</Button>
          </Form-item>
        </Form>
    </Card>
  </div>
</template>

<script>
// 根据你的实际请求api.js位置路径修改
import { editDeviceBuy } from "@/api/busi/data-manage/device-buy.js";
export default {
  name: "addEdit",
  components: {
  },
  props: {
    data: Object,
    addEditFlag: String
  },
  data() {
    return {
      submitLoading: false, // 表单提交状态
      form: { // 添加或编辑表单对象初始化数据
        telno: "",
        fsrq: "",
        tradeno: "",
        imei: "",
        ensn: "",
        imei2: "",
        ensn2: "",
        buytype: "",
      },
      // 表单验证规则
      formValidate: {
      }
    };
  },
  methods: {
    init() {
      // 编辑情况下
      if(this.addEditFlag == "2") {
        this.form = this.data;
      }
    },
    handleReset() {
      this.$refs.form.resetFields();
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          editDeviceBuy(this.form).then(res => {
            this.submitLoading = false;
            if (res.success) {
              this.$Message.success("操作成功");
              this.submited();
            }
          });
        }
      });
    },
    close() {
      this.$emit("close", true);
    },
    submited() {
      this.$emit("submited", true);
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style lang="less">
// 建议引入通用样式 具体路径自行修改 可删除下面样式代码
// @import "../../../styles/single-common.less";
.edit-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .back-title {
        color: #515a6e;
        display: flex;
        align-items: center;
    }

    .head-name {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        color: #17233d;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .window-close {
        z-index: 1;
        font-size: 12px;
        position: absolute;
        right: 0px;
        top: -5px;
        overflow: hidden;
        cursor: pointer;

        .ivu-icon-ios-close {
            color: #999;
            transition: color .2s ease;
        }
    }

    .window-close .ivu-icon-ios-close:hover {
        color: #444;
    }
}
</style>